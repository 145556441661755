import React from 'react';
import css from './CareCommunityOptions.module.css';
import classNames from 'classnames';
import MemoryIcon from '../../../components/MemoryIcon/MemoryIcon';
import LivingIcon from '../../../components/LivingIcon/LivingIcon';
import RespiteCareIcon from '../../../components/RespiteCareIcon/RespiteCareIcon';
import IndependentLivingIcon from '../../../components/IndependentLivingIcon/IndependentLivingIcon';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { searchPageDefaultUrl } from '../../../util/uiHelpers';

const CareCommunityOptions = () => {
  const intl = useIntl();
const history=useHistory()
  return (
    <section className={css.careCommunityOptions}>
      <div className={'container'}>
        <div className={css.careCommunity_wrapper}>
          <div className={css.titleSection}>
            <h2 className={'textCenter'}>
              {intl.formatMessage({ id: 'CareCommunityOptions.Title' })}{' '}
              <span className={css.colorhigh}>
                {intl.formatMessage({ id: 'CareCommunityOptions.TitleHighlight' })}
              </span>
            </h2>
            <p className={'textCenter'}>
              {intl.formatMessage({ id: 'CareCommunityOptions.Description' })}
            </p>
          </div>
          <div
            className={classNames(
              css.optionsSection,
              'd-flex',
              'align-items-center',
              'justify-content-center'
            )}
          >
            <button
              className={classNames(
                css.optionButton,
                'd-flex',
                'align-items-center',
                'justify-content-center'
              )}
              onClick={()=> history.push('/s?pub_categoryLevel1=assisted-living')}
            >
              <span
                role="img"
                aria-label={intl.formatMessage({ id: 'CareCommunityOptions.Aria.AssistedLiving' })}
              >
                <LivingIcon />
              </span>
              {intl.formatMessage({ id: 'CareCommunityOptions.AssistedLiving' })}
            </button>
            <button
              className={classNames(
                css.optionButton,
                'd-flex',
                'align-items-center',
                'justify-content-center'
              )}
              onClick={()=> history.push('/s?pub_categoryLevel1=memory-care')}

            >
              <span
                role="img"
                aria-label={intl.formatMessage({ id: 'CareCommunityOptions.Aria.MemoryCare' })}
              >
                <MemoryIcon />
              </span>
              {intl.formatMessage({ id: 'CareCommunityOptions.MemoryCare' })}
            </button>
            <button
              className={classNames(
                css.optionButton,
                'd-flex',
                'align-items-center',
                'justify-content-center'
              )}
              onClick={()=> history.push('/s?pub_categoryLevel1=respite-care')}

            >
              <span
                role="img"
                aria-label={intl.formatMessage({ id: 'CareCommunityOptions.Aria.RespiteCare' })}
              >
                <RespiteCareIcon />
              </span>
              {intl.formatMessage({ id: 'CareCommunityOptions.RespiteCare' })}
            </button>
            <button
              className={classNames(
                css.optionButton,
                'd-flex',
                'align-items-center',
                'justify-content-center'
              )}
              onClick={()=> history.push('/s?pub_categoryLevel1=independent-living')}

            >
              <span
                role="img"
                aria-label={intl.formatMessage({
                  id: 'CareCommunityOptions.Aria.IndependentLiving',
                })}
              >
                <IndependentLivingIcon />
              </span>
              {intl.formatMessage({ id: 'CareCommunityOptions.IndependentLiving' })}
            </button>
          </div>

          <div
            className={classNames(
              css.browseListings,
              'd-flex',
              'align-items-center',
              'justify-content-center'
            )}
            onClick={()=> history.push(searchPageDefaultUrl())}

          >
            <button className={'browseButton btnDefault'}>
              {intl.formatMessage({ id: 'CareCommunityOptions.BrowseListings' })}
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CareCommunityOptions;
