import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import bannerImage from '../Assets/bannerImage.png';
import css from './BannerComp.module.css';
import ButtonComponent from '../ButtonComponent/ButtonComponent';
import IconMap from '../../../components/IconMap/IconMap';
import IconCalender from '../../../components/IconCalender/IconCalender';
import { useIntl } from 'react-intl';
import Iconbackquote from '../../../components/Iconbackquote/Iconabackquote';
import TopbarSearchForm from '../../TopbarContainer/Topbar/TopbarSearchForm/TopbarSearchForm';
import { useConfiguration } from '../../../context/configurationContext';
import { isMainSearchTypeKeywords, isOriginInUse } from '../../../util/search';
import { useRouteConfiguration } from '../../../context/routeConfigurationContext';
import { parse } from '../../../util/urlHelpers';
import { useLocation } from 'react-router-dom';
import { createResourceLocatorString } from '../../../util/routes';
import { Form } from 'react-final-form';
import { FieldDateInput } from '../../../components';
import { searchPageDefaultUrl } from '../../../util/uiHelpers';

const familiesServed = 400;
const happyHomes = 2400;

const BannerComp = () => {
  const intl = useIntl();
  const history = useHistory();
  const location = useLocation();
  const config = useConfiguration();
  const routeConfiguration = useRouteConfiguration();

  const { keywords, address, origin, bounds } = parse(location.search, {
    latlng: ['origin'],
    latlngBounds: ['bounds'],
  });

  const topbarSearcInitialValues = () => {
    if (isMainSearchTypeKeywords(config)) {
      return { keywords };
    }

    const locationFieldsPresent = isOriginInUse(config)
      ? address && origin && bounds
      : address && bounds;
    return {
      location: locationFieldsPresent
        ? {
            search: address,
            selectedPlace: { address, origin, bounds },
          }
        : null,
    };
  };

  const initialSearchFormValues = topbarSearcInitialValues();

  function handleSubmit(values) {
    const topbarSearchParams = () => {
      if (isMainSearchTypeKeywords(config)) {
        return { keywords: values?.keywords };
      }

      const { search, selectedPlace } = values?.location;
      const { origin, bounds } = selectedPlace;
      const originMaybe = isOriginInUse(config) ? { origin } : {};

      return {
        ...originMaybe,
        address: search,
        bounds,
      };
    };
    const searchParams = {
      ...topbarSearchParams(),
    };
    history.push(createResourceLocatorString('SearchPage', routeConfiguration, {}, searchParams));
  }

  const onSubmit = values => {
    console.log('Form submitted with values:', values);
  };

  return (
    <div className={css.bannerComp}>
      <div className={css.fristSection}>
        <div className="container">
          <div className={`${css.wrapperBanner} d-grid`}>
            <div className={css.leftBannerContent}>
              <h1>
                {intl.formatMessage({ id: 'CustomHomePage.ConnectWitha' })}{' '}
                <span className={css.highlight}>
                  {intl.formatMessage({ id: 'CustomHomePage.CareCommunity' })}
                </span>{' '}
                {intl.formatMessage({ id: 'CustomHomePage.Today' })}
              </h1>
            </div>
            <div className={css.rightBannerContent}>
              <p>{intl.formatMessage({ id: 'CustomHomePage.LoremText' })}</p>
              <div className={css.wrapperSearch}>
                <div className={css.searchinput}>
                  <div className={css.formField}>
                    <span className={css.icon}>
                      <IconMap />
                    </span>
                    <TopbarSearchForm
                      onSubmit={handleSubmit}
                      initialValues={initialSearchFormValues}
                      appConfig={config}
                    />
                  </div>
                  <div className={css.formField}>
                    <span className={css.icon}>
                      <IconCalender />
                    </span>

                    <Form
                      onSubmit={onSubmit}
                      render={({ handleSubmit, submitting, pristine }) => (
                        <form onSubmit={handleSubmit}>
                          <FieldDateInput
                            name="date"
                            id="date-picker"
                            placeholderText={intl.formatMessage({
                              id: 'CustomHomePage.MoveInDate',
                            })}
                          />
                          <ButtonComponent
                            value={intl.formatMessage({ id: 'CustomHomePage.Search' })}
                            type="submit"
                            customClass="customClass"
                            variant="primary"
                            onClick={() => {
                              history.push(searchPageDefaultUrl());
                            }}
                          />
                        </form>
                      )}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className={css.imageSection}>
          <img
            src={bannerImage}
            alt={intl.formatMessage({ id: 'CustomHomePage.CommunityImageAlt' })}
            className={css.communityImage}
          />
        </div>
      </div>
      <div className={css.containerCounter}>
        <div className={`${css.statSection} d-grid`}>
          <div className={`${css.counter} d-grid`}>
            <div className={css.stat}>
              <h2>
                {intl.formatMessage(
                  { id: 'CustomHomePage.FamiliesServedCount' },
                  { count: familiesServed }
                )}{' '}
                <span className={css.colorD}>+</span>
              </h2>
              <p>{intl.formatMessage({ id: 'CustomHomePage.FamiliesServed' })}</p>
            </div>
            <div className={css.stat}>
              <h2>
                {intl.formatMessage(
                  { id: 'CustomHomePage.HappyHomesCount' },
                  { count: happyHomes }
                )}{' '}
                <span className={css.colorD}>+</span>
              </h2>
              <p>{intl.formatMessage({ id: 'CustomHomePage.HappyHomes' })}</p>
            </div>
          </div>
          <div className={`${css.quoteSection} d-flex align-items-center`}>
            <div className={`${css.contentquote} d-grid align-items-center`}>
              <Iconbackquote />
              <blockquote>{intl.formatMessage({ id: 'CustomHomePage.QuoteText' })}</blockquote>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BannerComp;
