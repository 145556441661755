import React from 'react';
import css from './CareAndConnectionSection.module.css'; // Import the CSS module
import HeadingComponent from '../HeadingBlockComp/HeadingComponent';
import ButtonComponent from '../ButtonComponent/ButtonComponent';
import IconTiltArrow from '../../../components/IconTiltArrow/IconTiltArrow';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { searchPageDefaultUrl } from '../../../util/uiHelpers';

const CareAndConnectionSection = () => {
  const intl = useIntl();
  const history = useHistory();

  return (
    <section className={css.careConnectionSection}>
      <div className={css.titleSection}>
        <HeadingComponent
          title={intl.formatMessage({ id: 'CareAndConnectionSection.Title' })}
          subTitle={intl.formatMessage({ id: 'CareAndConnectionSection.SubTitle' })}
          isCustomClass={true}
        />
      </div>

      <div className={css.buttonSection}>
        <ButtonComponent
          variant="primary"
          value={intl.formatMessage({ id: 'CareAndConnectionSection.ButtonValue' })}
          icon={<IconTiltArrow />}
          onClick={()=> history.push(searchPageDefaultUrl())}
        />
      </div>
    </section>
  );
};

export default CareAndConnectionSection;
